<template>
  <div class="merchant" :class="{deactivated: merchant.enabled == 0, deleted: merchant.deleted == 1}">
    <form class="merchant-data" @submit.prevent="save">
      <span class="readonly"> id: {{merchant.id}} </span>
      <span class="readonly"> merchant: {{merchant.hash}} </span>
      <label for="name">Название</label>
      <input required @input="setEdited(true)" id="name" type="text" autocomplete="off" v-model="merchant.name">

      <label for="pags">Платежный агент</label>
      <PayAgentSelect :value="merchant.paymentagent" @selected="onAgent" class="input" id="pags"/>

      <label>Дополнительные свойства</label>

      <div class="listed" v-for="(value, name) in params" :key="name">
        <label :for="name"> {{ name }} </label>
        <input @input="uparam" :id="name" type="text" :value="value">
      </div>

      <input type="submit" style="display:none" id="subs">
    </form>
    <div class="actions">
      <router-link :to="'/codegenerator/'+merchant.hash+'/'+merchant.name" class="input rtl code">Код для вставки</router-link>

      <input @click="enb" v-if="merchant.enabled == 1" type="button" value="Деактивировать">
      <input @click="enb" v-else type="button" value="Активировать">
      
      <input @click="del" v-if="merchant.deleted == 1" type="button" value="Восстановить">
      <input @click="del" v-else type="button" value="Удалить">
      <router-link :to="'/shops/'+merchant.id+'/'+merchant.name" class="input rtl">Рестораны ></router-link>
      
      <input @click="trysave" v-if="edited" type="button" value="Сохранить">
    </div>
  </div>
</template>

<script>
import PayAgentSelect from '@/components/PayAgentSelect'
import {apirequest} from '@/api.js'

export default {
  data(){
    return{
      params:{},
      editing:{
        creator: 0,
        deleted: 0,
        dtcreate: '',
        enabled: 1,
        hash: "",
        id: 1,
        name: "",
        paymentagent: "none"
      },
      edited: false
    }
  },
  props:['merchant'],
  methods:{
    enb(){
      this.merchant.enabled = 1 - this.merchant.enabled
      this.setEdited(true)
    },
    del(){
      this.merchant.deleted = 1 - this.merchant.deleted
      this.setEdited(true)
    },
    onAgent(val){
      this.merchant.paymentagent = val
      this.setEdited(true)
    },
    uparam(e){
      this.params[e.target.id] = e.target.value
      this.setEdited(true)
    },
    setEdited(val){
      if (val) {
        this.edited = val
      } else {
        this.edited = true
      }
    },
    save(){
      this.edited = false
      this.$emit('save',{was: this.editing, new: this.merchant, props: this.params})
    },
    trysave(){
      document.getElementById('subs').click()
    }
  },
  watch:{
    /*merchant(){
      this.edited = false
      Object.assign(this.editing, this.merchant)
      apirequest('mparams?merchant='+this.merchant.id)
        .then(data => {
            this.params = data.data
          }
        )
    }*/
  },
  components:{
    PayAgentSelect
  },
  mounted(){
    Object.assign(this.editing, this.merchant)
    apirequest('mparams?merchant='+this.merchant.id)
      .then(data => {
          this.params = data.data
        }
      )
  },
  beforeDestroy(){
    if (this.edited == true) {
      Object.assign(this.merchant, this.editing)
      this.edited = false
    }
  }
}
</script>

<style scoped>

.readonly {
  font-weight: 600;
  font-size: 1.2rem;
}

.deactivated {
  background-color: #f39d1244;
}

.deleted {
  background-color: #c0392b44;
}

.merchant{
  position: absolute;
  left: 16rem;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: .5rem;
  font-size: .8rem;
}

.merchant-data{
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
}

.listed{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
}

.actions {
  min-width: 10rem;
  width: 15rem;
  padding: 10px;
}

.actions input[type=button] {
  max-width: 9rem;
  cursor: pointer;
  pointer-events: all;
}

input, .input {
  font-size: 1.0rem;

  outline: none;
  border: none;
  border-radius: 4px;

  border-bottom: solid 3px #1abc9c;

  transition: all .5s ease;
  width: 100%;
  
  padding: 10px;

  text-decoration: none;

  display: block;
  
}

input:invalid {
  border-bottom: solid 3px #e74c3c;
}

input[type=submit] {
  border: none;
  background-color: #1abc9c;
  cursor: pointer;
  padding: .5rem;
  transition: all .3s ease;
  color: #ecf0f1;
}

input[type=submit]:hover, input[type=submit]:disabled{
  background-color: #16a085;
  color: white;
  
}

input[type=submit]:disabled {
  cursor: wait;
}

label {
  margin-top: .8rem;
}

input[type=button], .rtl {
  margin-top: .2rem;
  background-color: #2c3e50;
  border: none;
  color: #ecf0f1;
}

.rtl {
  max-width: 9rem;
  font-weight: 500;
}

input[type=button]:hover, .rtl:hover {
  background-color: #34495e;
  
}


.code {
  background-color: #8e44ad;
}

.code:hover {
  background-color: #9b59b6;
}

</style>