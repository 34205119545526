<template>
  <select @change="onChange">
    <option v-for="agent in agents" :key="agent.id" :id="agent.id" :value="agent.code" :selected="value == agent.code"> {{ agent.name }} </option>
  </select>
</template>

<script>
import {apirequest} from '@/api.js'

export default {
  props:['value'],
  data(){
    return{
      agents:[]
    }
  },
  methods:{
    onChange(val){
      this.$emit('selected', val.target.value)
    }
  },
  mounted(){
    apirequest('payagents')
      .then(data=>{
        this.agents = data.data
      })
  }
}
</script>