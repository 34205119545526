<template>
  <div id="app">
    <div class="design-head">
      <h1 class="main-logo">Delivery</h1>
      <span class="user"> {{user.fullname}} </span>
      <a href="#/login" v-if="user.fullname" class="user" @click="onLogout">Выход</a>
    </div>
    <component class="workspace" :is="layout" @userdata="onLogin">
      <router-view/>
    </component>
  </div>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout'  
import BasicLayout from '@/layouts/BasicLayout'

export default {
  data(){
    return {
      user: {}
    }
  },
  methods:{
    onLogin(data){
      this.user = data
    },
    onLogout(){
      this.user = {}
      sessionStorage.removeItem('tphash')
    }
  },
  computed:{
    layout(){
      return (this.$route.meta.layout || 'basic')+'-layout'
    }
  },
  components:{
    BasicLayout,
    LoginLayout
  },
  mounted(){
    console.log(this.layout)
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}

:root {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

#app {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  background-color: #ecf0f1;
  font-size: inherit;
}

#app .design-head {
  background-color: #16a085;

  position: fixed;
  top:0;
  left: 0;
  right: 0;
  height: 2.5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 20px;
}

#app .design-head .main-logo {
  color: #ecf0f1;
  font-size: 1.5rem;
}

#app .workspace{
  position: fixed;
  top:2.5rem;
  left: 0;
  right: 0;
  height: calc(100vh - 2.5rem);
  overflow: hidden;

}

#app .user {
  color: #ecf0f1;
  text-decoration: none;
}

</style>
