<template>
  <form class="login-window" @submit.prevent="logmein">
    <div class="inputcontainer">
      <label for="login">Email</label>
      <input v-model="lg" type="text" required placeholder="" id="login">
    </div>
    
    <div class="inputcontainer">
      <label for="password">Пароль</label>
      <input v-model="pwd" type="password" required placeholder="" id="password">
    </div>
    
    <span class="errormessage"> {{errm}} </span>

    <div class="inputcontainer">
      <input :disabled="inwork" type="submit" value="Войти">
    </div>
    
  </form>
</template>

<script>
import {apirequest} from '@/api.js'

export default {
  data(){
    return {
      errm: '',
      lg:'',
      pwd:'',
      inwork: false
    }
  },
  watch:{
    lg(val){
      sessionStorage.setItem('login', val)
    }
  },
  mounted(){
    this.lg = sessionStorage.getItem('login') || ''
  },
  methods:{
    logmein(){
      this.inwork = true
      let p = this.pwd
      this.errm = ''
      this.pwd = ''
      apirequest('login', {login: this.lg, pass: p})  
        .then(data => {
          sessionStorage.setItem('tphash', data.token)
          window.location.hash = '#/'
          this.inwork = false
        })
        .catch( err => {
          this.errm = err
          this.inwork = false
        })
    }
  }
}
</script>

<style scoped>

.login-window {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  border-radius: 5px;
  border: solid .1rem #ecf0f1;

  padding: .5rem;

}

.inputcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: .5rem;
}

.inputcontainer input {
  font-size: 1.2rem;

  outline: none;
  border: none;
  border-radius: 4px;

  border-bottom: solid 3px #1abc9c;

  transition: all .5s ease;
  min-width: 20rem;
  width: auto;

  padding: 10px;
}

.inputcontainer input:invalid {
  border-bottom: solid 3px #e74c3c;
}

.inputcontainer input[type=submit] {
  border: none;
  background-color: #1abc9c;
  cursor: pointer;
  padding: .5rem;
  transition: all .3s ease;
  color: #ecf0f1;
}

.inputcontainer input[type=submit]:hover, input[type=submit]:disabled{
  background-color: #16a085;
  color: white;
  
}

input[type=submit]:disabled {
  cursor: wait;
}

.errormessage {
  font-size: .8rem;
  color: #e74c3c;
  white-space: pre-line;
}

</style>
