// let url = 'http://192.168.1.7:5000/dashapi/'
let url = 'https://dev.carbis.ru/dashapi/'

let apirequest = async (method, body) => {
    let response = undefined
    try{
        if (body){
            response = await fetch(url+method, {
                method: 'POST',
                redirect: 'manual',
                headers:{
                    'Content-Type': 'application/json',
                    'tphash': sessionStorage.getItem('tphash') || ''
                },
                body: JSON.stringify(body)
            })
        } else {
            response = await fetch(url+method, {
                redirect: 'manual',
                headers:{
                    'tphash': sessionStorage.getItem('tphash') || ''
                }
            })
        }
    } catch { err => {
            console.log(err)
        }
    }

    if (!response){
        throw 'Нет соединения.'
    }
    
    if (response.status == 401) {
        window.location.hash="#/login"
        return undefined
    }
        
    let data = await response.json();
    if (!data.result){
        console.error('Response for '+method+': '+data.msg)
        throw data.msg
    } else {
        return data
    }
}


export {
    apirequest
}