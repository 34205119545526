<template>
  <div class="codegenerator">
    <h1>Генератор кода</h1>
    <router-link class="action" to="/">Назад</router-link>
    <h2>{{$route.params.name}}</h2>
    <label for="versions">Выберите версию веб-приложения</label>
    <div class="actions">
      <input type="button" class="action" v-for="app in apps" :key="app" :value="app" @click="build(app)">
    </div>
    <div v-if="version != ''" class="gendata">
      <h3>HTML</h3>
      <span>Вставьте HTML содержимое, приведенное ниже в страницу сайта</span>
      <pre id="rawhtml">
        {{gendata.html}}
      </pre>
      <hr>

      <h3>iframe</h3>
      <span>Вставьте HTML содержимое, приведенное ниже в страницу сайта</span>
      <pre id="rawhtml">
        {{gendata.iframe}}
      </pre>
      <hr>

      <h3>Прямая ссылка (для iframe)</h3>
      <span>Вставьте ссылку в src атрибут iframe на сайте</span>
      <pre id="rawhtml">
        {{gendata.src}}
      </pre>
      <hr>

      <h3>Проверка "На лету"</h3>
      <iframe :src="gendata.src"></iframe>


    </div>

  </div>
  
</template>

<script>
import {apirequest} from '@/api.js'

export default {
  data(){
    return{
      apps:[],
      version:'',
      gendata:{
        html: '',
        iframe: '',
        src: ''
      }
    }
  },
  methods:{
    build(ver){
      this.version = ver
      
      this.gendata.html= '<link rel=stylesheet href="https://dev.carbis.ru/shopapp/'+this.version+'/style">\n'+
      '<div id="minidelivery" merchant="'+this.$route.params.hash+'"></div>\n'+
      '<script src="https://dev.carbis.ru/shopapp/'+this.version+'/vendor"><'+"/"+'script>\n'+
      '<script src="https://dev.carbis.ru/shopapp/'+this.version+'/app"><'+"/"+'script>'

      this.gendata.iframe = '<iframe src="https://dev.carbis.ru/shopapp/'+this.version+'/shop?merchant='+this.$route.params.hash+'"></iframe>'

      this.gendata.src = 'https://dev.carbis.ru/shopapp/'+this.version+'/shop?merchant='+this.$route.params.hash
    }
  },
  mounted(){
    apirequest('webapps')
      .then(data => {
        this.apps = data.data
      })
  }
}
</script>

<style scoped>

.codegenerator {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.gendata {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;

}



h1,h2 {
  margin: 0.2rem;
}

iframe {
  width: 1200px;
  height: 60vh;
}

pre {
  white-space: pre-line;
  height: auto;
  width: 100%;
  text-align: left;
  background-color: #2c3e50;
  color: #ecf0f1;
  font-size: 0.8rem;
  padding: 1rem;
}

label {
  margin-top: 1rem;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: left;
  border-radius: 4px;
  border: none;
  margin: .1rem 1rem .1rem .1rem;
  padding: .3rem;
  text-align: center;
  font-size: 1.1rem;
  width: 100%;
  /*background-color: #16a085;*/
  cursor: pointer;
  color: #0e1e22;
}

.action {
  border: none;
  border-radius: 4px;
  outline: none;
  max-width: 5rem;
  font-size: 1.1rem;
  width: 100%;
  margin: .1rem;
  background-color: #2980b9;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.small {
  width: auto;
  min-width: 2rem;
}

.action:hover{
  background-color: #3498db;
}

</style>