<template>
    <div class="centralizer">
        <router-view />
    </div>
</template>

<script>
export default {
    name:'LoginLayout'
}
</script>

<style scoped>
div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

</style>