<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import {apirequest} from '@/api.js'

export default {
  name:'BasicLayout',
  mounted(){
    apirequest('getme')
      .then((data) => {
        this.$emit('userdata', data.data)
      })
      .catch( () => {
          window.location.hash="#/login"
        }
      )
  }
}
</script>

<style scoped>

</style>