<template>
  <div class="home">
    <Merchants @merchant="onMerchant"/>
    <MerchantParams @save="saveMerchant" v-if="selected" :merchant="merchant"/>
  </div>
</template>

<script>
import Merchants from '@/components/Merchants'
import MerchantParams from '@/components/MerchantParams'
import {apirequest} from '@/api.js'


export default {
  name: 'Home',
  data(){
    return{
      merchant: undefined,
      selected: false
    }
  },
  methods:{
    onMerchant(merchant){
      sessionStorage.setItem('merchant', merchant.id)
      this.selected = false
      setTimeout(()=>{
        this.merchant = merchant
        this.selected = true
      },100)
    },
    saveMerchant(data){
      let obj = {
        merchant: data.new,
        props: data.props
      }
      apirequest('merchants', obj)
        .then(res=>{
          console.log(res)
          if (this.merchant.id == 0) {
            this.merchant.id = res.ident
            this.merchant.hash = res.hash
          }
          this.selected = false
        })

    }
  },
  components: {
    Merchants,
    MerchantParams
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
</style>
