<template>
  <div class="merchants">
    <div class="actions">
      <router-link class="action" to="/">&lt; Назад</router-link>
    </div>
    <span class="actions">Рестораны корпорации "{{ name }}"
    </span>
    <div class="actions">
      <button title="Новый магазин" class="action" @click="newshop"><plus class="plus"/></button>
      <button title="Показывать удаленные" class="action" :class="{selected: showdeleted}" @click="del"><trash class="plus"/></button>
    </div>
    <div class="actions">
      <input class="actions" type="text" v-model="search" placeholder="Поиск">
      <button @click="search = ''" class="action small">x</button>
    </div>
    <button :class="{deactivated: shop.enabled == 0, deleted: shop.deleted == 1}" v-for="shop in shops" :key="shop.id" class="merchant" @click="$emit('shop', shop)"> {{shop.name}} </button>
  </div>    
</template>

<script>
import {apirequest} from '@/api.js'
import plus from '@/assets/svgPlus'
import trash from '@/assets/svgTrash'

export default {
  props:['merchant', 'name'],
  data(){
    return{
      shopsRef: [],
      showdeleted: false,
      search: ''
    }
  },
  computed:{
    shops(){
      return this.shopsRef.filter(m => {
          if (this.search == ''){
            return  ((m.deleted == 0) || (this.showdeleted)) && m.id > 0
          } else {
            return (m.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) && ((m.deleted == 0) || (this.showdeleted)) && m.id > 0
          }
        } 
      )
    }
  },
  methods:{
    newshop(){
      let obj = {
        id: 0, 
        merchant: +this.merchant,
        name: '', 
        address: '', 
        description: '', 
        dhurl: '', 
        dhtoken: '', 
        imagesurl: '', 
        noimage: '', 
        minimalsum: 0, 
        allowdlv: 1, 
        allowta: 1, 
        allowcash:1, 
        allowcard: 1, 
        allowonline: 1, 
        enabled:1, 
        deleted: 0, 
        creator: 0,
        dtcreate: new Date()
      }
      this.shopsRef.unshift(obj)
      this.$emit('shop', obj)
    },
    del(){
      this.showdeleted = ! this.showdeleted
    }
  },
  mounted(){
    apirequest('shops?merchant='+this.merchant)
      .then(data => {
        this.shopsRef = data.data
      })      
  },
  components:{
    plus,
    trash
  }
}
</script>

<style scoped>

button {
  cursor: pointer;
}

.merchants {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  overflow-y:auto;
}

.merchant {
  display: inline-block;
  outline: none;
  border-radius: 4px;
  border: none;
  margin: .1rem 1rem .1rem .1rem;
  padding: .3rem;
  text-align: center;
  font-size: 1.1rem;
  width: 100%;
  max-width: 15rem;
  background-color: #16a085;
  cursor: pointer;
  color: #ecf0f1;
}

.merchant::selection {
  background-color: red;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  border: none;
  margin: .1rem 1rem .1rem .1rem;
  padding: .3rem;
  text-align: center;
  font-size: 1.1rem;
  width: 100%;
  max-width: 15rem;
  /*background-color: #16a085;*/
  cursor: pointer;
  color: #0e1e22;
}

.action {
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: 1.1rem;
  width: 100%;
  margin: .1rem;
  background-color: #2980b9;

  color: #ecf0f1;
  text-decoration: none;
}

.small {
  width: auto;
  min-width: 2rem;
}

.action:hover{
  background-color: #3498db;
}

.selected{
  background-color: #d35400;
}

.selected:hover{
  background-color: #e67e22;  
}



.merchant:hover {
  background-color: #1abc9c;
}

.deactivated {
  background-color: #f39c12;
}

.deactivated:hover {
  background-color: #f1c40f;
}

.deleted {
  background-color: #c0392b;
}

.deleted:hover {
  background-color: #e74c3c;
}



svg{
  width: auto;
  height: 1.1rem;
  transform: translate(0%, 0.2rem);
  color: white;
  /*fill: #ecf0f1;*/
}
</style>