<template>
  <div class="merchant" :class="{deactivated: shop.enabled == 0, deleted: shop.deleted == 1}">
    <form class="merchant-data" @submit.prevent="save">
      <span class="readonly"> id: {{shop.id}} </span>
      <label for="name">Название</label>
      <input required @input="setEdited(true)" id="name" type="text" autocomplete="off" placeholder="Название ресторана" v-model="shop.name">

      <label for="address">Адрес</label>
      <input required @input="setEdited(true)" id="address" type="text" autocomplete="off" placeholder="Адрес ресторана" v-model="shop.address">

      <label for="desc">Описание</label>
      <input required @input="setEdited(true)" id="desc" type="text" autocomplete="off" placeholder="Краткое описание" v-model="shop.description">

      <label for="dhurl">URL к DeliveryHatch</label>
      <input required @input="dhurl" id="dhurl" type="text" autocomplete="off" placeholder="IP адрес DeliveryHatch в ресторане" v-model="shop.dhurl">

      <label for="dhtoken">API Token DeliveryHatch</label>
      <input required @input="setEdited(true)" id="dhtoken" type="text" autocomplete="off" placeholder="DeliveryHatch.ini > [APITOKEN]" v-model="shop.dhtoken">

      <label for="imagesurl">URL к папке с картинками</label>
      <input required @input="setEdited(true)" id="imagesurl" type="text" placeholder="URL где расположены картинки для блюд" autocomplete="off" v-model="shop.imagesurl">
      
      <label for="noimage">URL к картинке-заглушке</label>
      <input required @input="setEdited(true)" id="noimage" type="text" placeholder="Запасная картинка для блюда" autocomplete="off" v-model="shop.noimage">

      <label for="minimalsum">Минимальная сумма заказа</label>
      <input required @input="setEdited(true)" id="minimalsum" min="0" placeholder="0 - нет ограничений" type="number" autocomplete="off" v-model="shop.minimalsum">

      <label for="allowdlv">Разрешить заказы на доставку</label>
      <input required @input="setEdited(true)" id="allowdlv" min=0 max=1 type="number" autocomplete="off" v-model="shop.allowdlv">
          
      <label for="allowta">Разрешить заказы на самовывоз</label>
      <input required @input="setEdited(true)" id="allowta"  min=0 max=1 type="number" autocomplete="off" v-model="shop.allowta">
  
      <label for="allowcash">Разрешить оплату наличными</label>
      <input required @input="setEdited(true)" id="allowcash"  min=0 max=1 type="number" autocomplete="off" v-model="shop.allowcash">
    
      <label for="allowcard">Разрешить оплату картой</label>
      <input required @input="setEdited(true)" id="allowcard"  min=0 max=1 type="number" autocomplete="off" v-model="shop.allowcard">
    
      <label for="allowonline">Разрешить оплату онлайн</label>
      <input required @input="setEdited(true)" id="allowonline"  min=0 max=1 type="number" autocomplete="off" v-model="shop.allowonline">
    

      <label>Дополнительные свойства</label>

      <div class="listed" v-for="(value, name) in params" :key="name">
        <label :for="name"> {{ name }} </label>
        <input @input="uparam" :id="name" type="text" :value="value">
      </div>
      <input type="submit" style="display:none" id="subs">
    </form>
    
    <div class="actions">
      <input @click="enb" v-if="shop.enabled == 1" type="button" value="Деактивировать">
      <input @click="enb" v-else type="button" value="Активировать">
      
      <input @click="del" v-if="shop.deleted == 1" type="button" value="Восстановить">
      <input @click="del" v-else type="button" value="Удалить">
      
      <input @click="trysave" v-if="edited" type="button" value="Сохранить">
    </div>
    
  </div>
</template>

<script>
import {apirequest} from '@/api.js'

export default {
  data(){
    return{
      params:{},
      editing:{},
      edited: false
    }
  },
  props:['shop'],
  methods:{
    dhurl(){
      let reg = this.shop.dhurl.match(/^(http:\/\/|https:\/\/)(\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}:\d{1,5})(\/api\/v2\/ext\/)$/)
      if ((reg) && (reg.length == 4)) {
        document.getElementById('dhurl').setCustomValidity("")
      } else {
        document.getElementById('dhurl').setCustomValidity("Укажите адрес в формате http(s)://IP:PORT/api/v2/ext/")
      }
      this.setEdited(true)
    },
    enb(){
      this.shop.enabled = 1 - this.shop.enabled
      this.setEdited(true)
    },
    del(){
      this.shop.deleted = 1 - this.shop.deleted
      this.setEdited(true)
    },
    onAgent(val){
      this.shop.paymentagent = val
      this.setEdited(true)
    },
    uparam(e){
      this.params[e.target.id] = e.target.value
      this.setEdited(true)
    },
    setEdited(val){
      if (val) {
        this.edited = val
      } else {
        this.edited = true
      }
    },
    save(){
      this.edited = false
      this.$emit('save',{was: this.editing, new: this.shop, props: this.params})
    },
    trysave(){
      document.getElementById('subs').click()
    }
  },
  watch:{
    /*merchant(){
      this.edited = false
      Object.assign(this.editing, this.merchant)
      apirequest('mparams?merchant='+this.shop.id)
        .then(data => {
            this.params = data.data
          }
        )
    }*/
  },
  components:{
    
  },
  mounted(){
    Object.assign(this.editing, this.shop)
    apirequest('sparams?shop='+this.shop.id)
      .then(data => {
          this.params = data.data
        }
      )
  },
  beforeDestroy(){
    if (this.edited == true) {
      Object.assign(this.shop, this.editing)
      this.edited = false
    }
  }
}
</script>

<style scoped>

.readonly {
  font-weight: 600;
  font-size: 1.2rem;
}

.deactivated {
  background-color: #f39d1244;
}

.deleted {
  background-color: #c0392b44;
}

.merchant{
  position: absolute;
  left: 16rem;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: .5rem;
  font-size: .8rem;
}

.merchant-data{
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
}

.listed{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
}

.actions {
  min-width: 10rem;
  width: 15rem;
}

.actions input[type=button] {
  max-width: 9rem;
  cursor: pointer;
  pointer-events: all;
}

input, .input {
  font-size: 1.0rem;

  outline: none;
  border: none;
  border-radius: 4px;

  border-bottom: solid 3px #1abc9c;

  transition: all .5s ease;
  width: 100%;
  
  padding: 10px;
  
}

input:invalid {
  border-bottom: solid 3px #e74c3c;
}

input[type=submit] {
  border: none;
  background-color: #1abc9c;
  cursor: pointer;
  padding: .5rem;
  transition: all .3s ease;
  color: #ecf0f1;
}

input[type=submit]:hover, input[type=submit]:disabled{
  background-color: #16a085;
  color: white;
  
}

input[type=submit]:disabled {
  cursor: wait;
}

label {
  margin-top: .8rem;
}

input[type=button] {
  margin-top: .2rem;
  background-color: #2c3e50;
  border: none;
  color: #ecf0f1;
}

input[type=button]:hover {
  background-color: #34495e;
  
}

</style>