<template>
  <div class="home">
    <Shops @shop="onShop" :merchant="$route.params.merchant" :name="$route.params.name"/>
    <ShopParams @save="saveShop" v-if="selected" :shop="shop"/>
  </div>
</template>

<script>
import Shops from '@/components/Shops'
import ShopParams from '@/components/ShopParams'
import {apirequest} from '@/api.js'


export default {
  name: 'Home',
  data(){
    return{
      shop: undefined,
      selected: false
    }
  },
  methods:{
    onShop(shop){
      this.selected = false
      setTimeout(()=>{
        this.shop = shop
        this.selected = true
      },100)
    },
    saveShop(data){
      let obj = {
        shop: data.new,
        props: data.props
      }
      apirequest('shops', obj)
        .then(res=>{
          console.log(res)
          if (this.shop.id == 0) {
            this.shop.id = res.ident
          }
          this.selected = false
        })

    }
  },
  components: {
    Shops,
    ShopParams
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
</style>
