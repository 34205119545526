import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import MerchantHome from '@/views/MerchantHome'
import Shops from '@/views/ShopsHome'
import CodeGenerator from '@/components/CodeGenerator'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: MerchantHome
  },
  {
    path: '/shops/:merchant/:name',
    name: 'Shops',
    component: Shops
  },
  {
    path: '/codegenerator/:hash/:name',
    name: 'Shops',
    component: CodeGenerator
  },
  {
    path: '/login',
    name: 'LoginPage',
    meta:{layout: "login"},
    component: Login
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
